import React from 'react';
import { Box, Image, Text, Link as ExternalLink } from 'theme-ui';
import Logo from '../../images/logo.png';

import { GrInstagram, GrGithub, GrLinkedin } from 'react-icons/gr';
import LazyLoad from 'react-lazyload';

export default function Footer() {
  return (
    <Box sx={styles.container} as='div'>
      <Box sx={styles.logoContainer}>
        <LazyLoad>
          <Image src={Logo} alt='logo' />
        </LazyLoad>
      </Box>
      <Text as='p' sx={styles.copyright}>
        © Stefan Trinh {new Date().getFullYear()}
      </Text>

      <Box sx={styles.socialIconsContainer}>
        <ExternalLink
          href='https://www.github.com/stefantrinh1'
          target='_blank'
          rel='noopener'
        >
          <GrGithub />
        </ExternalLink>
        <ExternalLink
          href='https://www.linkedin.com/in/stefantrinh1/'
          target='_blank'
          rel='noopener'
        >
          <GrLinkedin />
        </ExternalLink>
        <ExternalLink
          href='https://www.instagram.com/stefan.codes/'
          target='_blank'
          rel='noopener'
        >
          <GrInstagram />
        </ExternalLink>
      </Box>
    </Box>
  );
}
const styles = {
  // ? === containers ===

  container: {
    backgroundColor: 'dark',
    padding: '0.5rem',
    color: 'lightGrey',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: ['', '', '', 'start'],
    alignItems: 'center',
  },

  logoContainer: {
    maxHeight: '35px',
    img: {
      height: '35px',
      width: 'auto',
    },
  },
  copyright: {
    marginLeft: ['0.5rem', '1rem'],
    color: 'white',
    fontSize: '0.8rem',
  },

  socialIconsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: ['auto', '', '2rem'],
    color: 'primary',
    a: {
      display: 'flex',
      ':nth-last-of-type(1)': {
        // backgroundColor: 'red',
        svg: {
          marginRight: '0rem',
        },
      },
    },

    svg: {
      height: '30px',
      width: '30px',
      margin: '0rem 0.5rem 0rem 0rem',
      color: 'primary',
      border: 'solid 1px',
      borderColor: 'primary',
      padding: '0.25rem',
      borderRadius: '500px',
      transition: 'all ease-in-out 0.5s',

      ':hover': {
        backgroundColor: 'primary',
        color: 'light',
      },
    },
  },
};
