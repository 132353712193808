import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Image, Heading, Link as ExternalLink, Text } from 'theme-ui';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import Logo from '../../images/stefantrinhlogo.png';
import Fade from 'react-reveal/Fade';
import { globalHistory } from '@reach/router';
import { GiHamburgerMenu } from 'react-icons/gi';
// import { SiVisualstudiocode, SiSketch } from 'react-icons/si';
import { MdTimeline, MdLocationOn } from 'react-icons/md';
// import { FaProjectDiagram, FaBlogger } from 'react-icons/fa';
import { GrMail, GrGithub, GrInstagram, GrLinkedin } from 'react-icons/gr';
// import { RiGalleryFill } from 'react-icons/ri';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import smallNavigation from './smallNavigation';
import careerNavMenuLinks from './careerNavMenuLinks';
import socialNavMenuLinks from './socialNavMenuLinks';

export default function Header(props) {
  // ? ===========================
  // ? ========   Data   =========
  // ? ===========================

  const data = useStaticQuery(graphql`
    query SiteMetaData {
      site {
        siteMetadata {
          siteUrl
          title
          description
          email
          location
          instagram
          github
          linkedin
          twitter
        }
      }
    }
  `);

  const siteMetaData = data.site.siteMetadata;
  // ? ========================
  // ? ==== State and Refs ====
  // ? ========================
  const [navOpen, setNavOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const navRef = useRef();
  navRef.current = scrolled;
  const isHomepage = props.location.pathname === '/';
  // ? ========================
  // ? ====== Functions =======
  // ? ========================

  const handleScroll = useCallback(() => {
    const show = window?.scrollY > 70;
    if (navRef.current !== show) {
      setScrolled(show);
    }
  }, []);

  // ? ========================
  // ? ======= Effects ========
  // ? ========================

  // sets state to scrolled if the user is not at the top of the page
  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  // disable scroll when nav open
  useEffect(() => {
    navOpen ? disablePageScroll() : enablePageScroll();
    return () => {};
  }, [navOpen]);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setNavOpen(false);
    });
  }, [navOpen]);

  // ? ========================
  // ? === Styles Variables ===
  // ? ========================

  const navOpenVariant = navOpen ? styles.navMenuOpen : {};
  const navBlockOpenVariant = navOpen ? styles.navBlockOpen : {};
  const containerScrolledVariant = scrolled ? styles.containerScrolled : {};
  const logoScrolledVariant = scrolled ? styles.logoContainerScrolled : {};
  const desktopNavScrolledVariant = scrolled ? styles.desktopNavScrolled : {};
  const containerFixedScrolledVariant = scrolled
    ? styles.containerScrolledFixed
    : {};
  const containerStyles = { ...styles.container, ...containerScrolledVariant };
  const containerFixedStyles = {
    ...styles.containerFixed,
    ...containerFixedScrolledVariant,
  };
  const containerStyleSelector = isHomepage
    ? containerStyles
    : containerFixedStyles;

  // ? ========================
  // ? === Render Fragments ===
  // ? ========================

  const renderSmallNav = () =>
    smallNavigation.map((item, index) => {
      const { name, link } = item;
      if (name === 'Home' && isHomepage) {
        return ''; // if homepage and nav link about to rendered is home link don't render
      } else {
        return (
          <Fade key={'smallNavLink' + index} top delay={500 + index * 100}>
            <AniLink
              paintDrip
              to={link}
              rel='noopener'
              duration={0}
              hex='#e01f5a'
            >
              {name}
            </AniLink>
          </Fade>
        );
      }
    });

  const renderNavMenuLinks = (menu) =>
    menu.map((item, index) => {
      const { name, link, icon } = item;

      return (
        <AniLink paintDrip to={link} rel='noopener' duration={0} hex='#e01f5a'>
          {icon()}
          {name}
        </AniLink>
      );
    });

  return (
    <>
      <Box sx={containerStyleSelector} as='header'>
        <AniLink paintDrip duration={0} hex='#e01f5a' to='/' rel='noopener'>
          <Box
            sx={{ ...styles.logoContainer, ...logoScrolledVariant }}
            className='logoContainer'
          >
            <Fade left>
              <Image src={Logo} alt='logo' />
            </Fade>
          </Box>
        </AniLink>

        <Box
          sx={{ ...styles.desktopNav, ...desktopNavScrolledVariant }}
          className='desktopNav'
        >
          {renderSmallNav()}
        </Box>

        <Box sx={styles.hamburgerContainer} className='hamburgerContainer'>
          <GiHamburgerMenu size={40} onClick={() => setNavOpen(!navOpen)} />
        </Box>
      </Box>
      <Box sx={{ ...styles.navMenu, ...navOpenVariant }} className='navMenu'>
        <Box sx={styles.navMenuLogo} className='navMenuLogo'>
          <Fade bottom cascade>
            <AniLink paintDrip duration={0} hex='#e01f5a' to='/' rel='noopener'>
              <Box>
                <Image src={Logo} alt='logo' />
              </Box>
              <Heading as='h2'>Stefan Trinh</Heading>
            </AniLink>
          </Fade>
        </Box>

        <Box sx={styles.links} className='links'>
          <Box sx={styles.linksMenu} className='links'>
            <Fade bottom cascade>
              <Heading as='h5'>Career</Heading>
              {renderNavMenuLinks(careerNavMenuLinks)}
            </Fade>
            <Fade bottom cascade>
              <Heading as='h5'>Social</Heading>
              {renderNavMenuLinks(socialNavMenuLinks)}
            </Fade>
          </Box>

          <Box
            sx={styles.contactLinkContainer}
            className='contactLinkContainer'
          >
            <Heading as='h5'>Contact</Heading>
            <Text as='p'>
              <MdLocationOn />
              {siteMetaData.location}
            </Text>
            <Text as='p'>
              <GrMail />
              {siteMetaData.email}
            </Text>

            <Box sx={styles.socialIcons} className='socialIcons'>
              <ExternalLink href={siteMetaData.github} rel='noopener'>
                <GrGithub />
              </ExternalLink>
              <ExternalLink href={siteMetaData.linkedin} rel='noopener'>
                <GrLinkedin />
              </ExternalLink>
              <ExternalLink href={siteMetaData.instagram} rel='noopener'>
                <GrInstagram />
              </ExternalLink>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{ ...styles.navBlock, ...navBlockOpenVariant }}
        onClick={() => setNavOpen(false)}
      ></Box>
    </>
  );
}
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'fixed',
    width: '100%',
    top: '0rem',
    color: 'text',
    padding: ['1rem 1rem 0rem', '', '', '2rem 2rem 0rem'],
    zIndex: '5000',
    transition: 'all ease-in-out 0.5s',
  },
  containerScrolled: {
    borderColor: 'primary',
  },
  containerFixed: {
    backgroundColor: 'dark',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'fixed',
    width: '100%',
    top: '0rem',
    padding: ['0.5rem 1rem', '0.5rem 2rem'],
    // padding: ['1rem 1rem 0rem', '', '', '2rem 2rem 0rem'],
    zIndex: '5000',
    transition: 'all ease-in-out 0.5s',
    color: 'white',
    '> a': {
      display: ['', '', 'none'],
    },

    '.desktopNav': {
      display: ['none', '', 'flex'],
    },
  },
  containerScrolledFixed: {
    '.desktopNav': {
      display: ['none', '', 'flex'],
    },
  },
  logoContainer: {
    maxWidth: ['60px', '', '', '80px'],
    transition: 'all ease-in-out 0.5s',
  },
  logoContainerScrolled: {
    maxWidth: ['60px', '', '', '80px'],
  },
  logo: {
    width: '100%',
  },
  hamburgerContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: ['0 0 0 auto'],
    color: 'primary',
  },
  desktopNav: {
    padding: '0rem 2rem',
    display: ['none', '', 'flex'],
    a: {
      padding: '1rem',
      height: '100%',
      color: 'inherit',
      display: 'flex',
      fontSize: '1.5rem',
      opacity: '0.8',
      fontWeight: '100',
      letterSpacing: '1px',
      // color: 'dark',
      transition: 'all ease-in-out 0.7s',
      flexDirection: 'column',
      // textShadow: '0px 0px 10px #eaeaea',
      '::after': {
        height: '2px',
        transition: ' all ease-in-out 0.7s',
        width: '0px',
        content: "''",
        backgroundColor: 'dark',
      },
      ':hover': {
        opacity: '0.4',
        // textDecoration: 'underline',
        '::after': {
          width: '100%',
        },
      },
    },
  },
  desktopNavScrolled: {
    display: 'none',
  },
  navMenu: {
    position: 'fixed',
    top: '0rem',
    left: '-100vw',
    backgroundColor: 'backgroundSecondary',
    height: '100vh',
    maxWidth: '80%',
    minWidth: '260px',
    zIndex: '6000',
    transition: 'all ease-in-out 0.5s',
    padding: ['0rem 0rem 1rem'],
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0px 20px 20px 0px',
  },
  navMenuOpen: {
    left: '0vw',
  },
  navBlock: {
    position: 'fixed',
    left: '-100vw',
    top: '0rem',
    backgroundColor: 'rgba(0,0,0,0.5)',
    // backdropFilter: 'blur(2px)',
    height: '100vh',
    width: '100vw',
    zIndex: '5500',
    transition: 'all ease-in-out 0.5s',
  },
  navBlockOpen: { left: '0vw' },

  navMenuLogo: {
    display: 'flex',
    width: '90%',
    margin: '0rem auto 0rem 0rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderBottom: 'solid 1px lightgrey',
    padding: '1rem',
    a: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    img: {
      maxWidth: ['40px', '50px'],
      marginRight: '0.5rem',
    },
    h2: {
      fontWeight: '200',
      color: 'text',
    },
  },
  links: {
    padding: ['0rem 1rem 1rem', '1rem 2rem'],
    overflowY: 'scroll',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    a: {
      variant: 'customStyles.iconText',
      margin: ['0.25rem 0rem', '', '0.5rem 0rem'],
      color: 'text',
      svg: {
        color: 'primary',
        marginRight: '0.75rem',
        width: '35px',
        height: '35px',
        borderRadius: '500px',
        border: ' 1px solid',
        borderColor: 'primary',
        padding: '0.25rem',
      },
      ':hover': {
        color: 'primary',
      },
    },
    h5: {
      fontSize: '0.9rem',
      opacity: '0.8',
      color: 'grey',
      margin: '0.75rem 0.25rem',
    },
  },

  linksMenu: {
    margin: '0rem 0rem 1rem',
  },

  contactLinkContainer: {
    margin: 'auto 0rem 0rem',
    color: 'dark',
    display: 'flex',
    flexDirection: 'column',
    borderTop: 'solid 1px',
    padding: '0.5rem 0rem',
    h5: {
      // color: 'light',
    },
    p: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.75rem',
      marginBottom: '0.5rem',
      svg: {
        marginRight: '0.5rem',
        width: '20px',
        height: '20px',
      },
    },
  },

  socialIcons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
};
