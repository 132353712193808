import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Logo from '../images/stefantrinhlogo.png';
function SEO({ pageTitle }) {
  const title = 'Stefan Trinh';
  const titleTemplate = `%s · Software Engineer · ${pageTitle}`;
  const url = 'www.stefantrinh.com';
  const description =
    'Stefan Trinh · Software Engineer Based In Utah USA · The final product is the goal. Making software products that are fast, reliable and visionally stunning.';
  const twitterUsername = '@stefantrinh';
  const image =
    'https://res.cloudinary.com/dpakxm3nm/image/upload/portfolio/laptopcode_l79gtd.png';

  return (
    <Helmet title={title} titleTemplate={titleTemplate}>
      <meta name='description' content={description} />
      <meta name='image' content={image} />
      {url && <meta property='og:url' content={url} />}

      {title && <meta property='og:title' content={title} />}

      {description && <meta property='og:description' content={description} />}

      {image && <meta property='og:image' content={image} />}

      <meta name='twitter:card' content='summary_large_image' />
      {twitterUsername && (
        <meta name='twitter:creator' content={twitterUsername} />
      )}
      {title && <meta name='twitter:title' content={title} />}
      {description && <meta name='twitter:description' content={description} />}
      {image && <meta name='twitter:image' content={image} />}

      <link rel='icon' href={Logo}></link>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
