// ? ===================================================
// ? =============  3rd Party Imports  =================
// ? ===================================================

import React, { createContext, useReducer, useEffect } from 'react';

// ? ===================================================
// ? ===============  Local Imports  ===================
// ? ===================================================

// ? ===================================================
// ? ===============  Default State  ===================
// ? ===================================================

const initialState = {
  navigationOpen: false,
  homepagePosition: 1,
  scrollLocked: false,
  modal: {
    modalOpen: false,
    message: '',
    modalType: 'information',
  },

  modalConfirm: {
    modalOpen: false,
    // message: '',
    children: false,
    confirmFunction: () => {},
    cancelFunction: () => {},
  },

  lightBox: {
    modalOpen: false,
    uri: '',
    caption: '',
  },
};

// ? ===================================================
// ? ==============  Context Creation  =================
// ? ===================================================

const store = createContext(initialState);
const { Provider } = store;

const ContextProvider = ({ children }) => {
  // all states are here and have their own reducer which allows multiple functions
  const [globalState, dispatch] = useReducer((state, action) => {
    // ? ===================================================
    // ? =================  Actions  =======================
    // ? ===================================================

    switch (action.type) {
      // ? === NAV ACTIONS ===

      case 'TOGGLE_NAV':
        return {
          ...state,
          navigationOpen: !state.navigationOpen,
        };

      // ? === HOMEPAGE Actions ===

      case 'SET_HOMEPAGE_POSITION':
        return {
          ...state,
          homepagePosition:
            action.position || action.position === 0
              ? action.position
              : state.homepagePosition,
        };

      // ? === MODAL ACTIONS ===

      case 'OPEN_MODAL':
        return {
          ...state,
          scrollLocked: true,
          modal: {
            modalOpen: true,
            message: action.message ? action.message : '',
            children: action.children ? action.children : false,
          },
        };

      case 'CLOSE_MODAL':
        return {
          ...state,
          scrollLocked: false,
          modal: {
            ...initialState.modal,
          },
        };

      // ? === Scroll ACTIONS ===

      case 'LOCK_SCROLL':
        return {
          ...state,
          scrollLocked: true,
        };

      case 'UNLOCK_SCROLL':
        return {
          ...state,
          scrollLocked: false,
        };

      // ? === LightBox ACTIONS ===

      case 'TOGGLE_LIGHTBOX':
        return {
          ...state,
          lightBox: {
            lightBoxOpen: action.toggle,
            uri: action.uri,
            caption: action.caption ? action.caption : '',
          },
        };
      case 'CLOSE_LIGHTBOX':
        return {
          ...state,
          lightBox: {
            lightBoxOpen: false,
            uri: '',
            title: '',
          },
        };

      // ? === DEFAULT ERROR ACTIONS ===

      default:
        alert('Error - No Action Could Be Found');
        throw new Error();
    }
  }, initialState);

  // ? ===================================================
  // ? =================  Functions  =====================
  // ? ===================================================

  const disablePageScroll = () => {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'hidden';
  };

  const enablePageScroll = () => {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'initial';
  };

  // ? ===================================================
  // ? =================  Effects  =======================
  // ? ===================================================

  // ? ============  Homepage Effects  ===================

  // ? ============  Generic Effects  ===================

  useEffect(() => {
    globalState.scrollLocked ? disablePageScroll() : enablePageScroll();
    return () => {};
  }, [globalState.scrollLocked]);

  // ? ============  Return  JSX  ===================

  return <Provider value={{ globalState, dispatch }}>{children}</Provider>;
};

export { store, ContextProvider };
