import React from 'react'
import {
  // FaPlaneDeparture,
  FaBlogger,
} from 'react-icons/fa'
import { RiGalleryFill } from 'react-icons/ri'

export default [
  // {
  //   name: 'Explore Social',
  //   link: '/social',
  //   icon: () => <FaPlaneDeparture />,
  // },
  {
    name: 'The Blog',
    link: '/blog',
    icon: () => <FaBlogger />,
  },

  {
    name: 'Gallery',
    link: '/gallery',
    icon: () => <RiGalleryFill />,
  },
]
