import React, { useContext } from 'react';
import { Box } from 'theme-ui';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Seo from './Seo';
import { store } from '../Context/Store';
import Modal from './UI/Modal';
import { AccessibilityWidget } from 'react-accessibility';

export default function Layout({ children, location }) {
  const { globalState, dispatch } = useContext(store);
  
  // grabs the pagetitle from path name be refactoring
  const stripTrailingSlash = (str) => {
    return str.endsWith('/') ? str.slice(0, -1) : str;
  };
  const pathName = stripTrailingSlash(location.pathname);
  const pathNameElements = pathName.split('/');
  const slug = pathNameElements[pathNameElements.length - 1];
  const dash = new RegExp('-', 'g');
  const parsedSlug = slug.replace(dash, '');
  const pageTitle = parsedSlug.replace(/\b\w/g, (l) => l.toUpperCase());

  const modalHasChildren = globalState?.modal?.children;

  return (
    <Box>
      <Seo pageTitle={pageTitle} />
      <Header location={location} />
      {children}
      <Footer />
      {globalState.modal?.modalOpen && (
        <Modal>{modalHasChildren ? modalHasChildren : ''}</Modal>
      )}

      <AccessibilityWidget />
    </Box>
  );
}
