// example theme file

// const customStyles = require('./customStyles')
import customStyles from './customStyles';

const index = {
  colors: {
    text: '#202020',
    textOnPrimary: 'white',
    primary: '#e01f5a',
    brandGreen: '#239565',
    secondary: '#ecb32e',
    brandBlue: '#36c5ef',
    brandYellow: '#ecb32e',
    light: '#e5e5e5',
    dark: '#202020',
    darkGrey: '#606060',
    lightGrey: '#c7c7c7',
    background: 'white',
    backgroundSecondary: '#eaeaea',
  },
  breakpoints: ['480px', '767px', '1024px', '1200px', '1500px', '1800px'],
  space: [
    '0rem',
    '0.25rem',
    '0.5rem',
    '0.75rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '1.75rem',
    '2rem',
    '2.5rem',
    '3rem',
    '3.5rem',
  ],
  fonts: {
    heading: 'Roboto, sans-serif',
    subheading: 'Roboto, sans-serif',
    body: 'Roboto, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    subheading: {
      fontFamily: 'subheading',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      minHeight: '100vh',
      h1: {
        variant: 'text.heading',
        fontSize: 5,
      },
      h2: {
        variant: 'text.heading',
        fontSize: 4,
      },
      h3: {
        variant: 'text.heading',
        fontSize: 3,
      },
      h4: {
        variant: 'text.heading',
        fontSize: 2,
      },
      h5: {
        variant: 'text.heading',
        fontSize: 1,
      },
      h6: {
        variant: 'text.heading',
        fontSize: 0,
      },
      a: {
        textDecoration: 'none',
        color: 'white',
      },
      pre: {
        fontFamily: 'monospace',
        overflowX: 'auto',
        code: {
          color: 'inherit',
        },
      },
      code: {
        fontFamily: 'monospace',
        fontSize: 'inherit',
      },
      table: {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: 0,
      },
      th: {
        textAlign: 'left',
        borderBottomStyle: 'solid',
      },
      td: {
        textAlign: 'left',
        borderBottomStyle: 'solid',
      },
    },
  },

  buttons: {
    primary: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'primary',
      color: 'light',
      border: 'solid 1px',
      borderColor: 'primary',
      transition: 'all ease-in-out 0.5s',
      padding: ['0.75rem 1rem', '0.75rem 1.5rem'],
      borderRadius: '5px',
      width: 'fit-content',
      fontSize: ['0.9rem', '1rem'],
      svg: {
        marginRight: '0.5rem',
      },
      ':hover': {
        // borderColor: 'light',
        color: 'primary',
        backgroundColor: 'transparent',
      },
    },
    secondary: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'left',
      backgroundColor: 'brandGreen',
      color: 'light',
      border: 'solid 1px',
      borderColor: 'brandGreen',
      transition: 'all ease-in-out 0.5s',
      padding: ['0.75rem 1rem', '0.75rem 1.5rem'],
      borderRadius: '5px',
      width: 'fit-content',
      fontSize: ['0.9rem', '1rem'],
      svg: {
        marginRight: '0.5rem',
      },
      ':hover': {
        // borderColor: 'light',
        color: 'brandGreen',
        backgroundColor: 'transparent',
      },
    },
    textOnly: {
      transition: 'all ease-in-out 0.3s',
      color: 'text',
      display: 'flex',
      textTransform: 'capitalize',
      backgroundColor: 'transparent',
      padding: '1rem 1rem 1rem 0rem',
      border: 'none',
      textAlign: 'left',
      ':hover': {
        opacity: '0.6',
      },
    },
  },

  customStyles: {
    ...customStyles,
  },
};

export default index;
