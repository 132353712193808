import React from 'react'
import { SiVisualstudiocode, SiSketch } from 'react-icons/si'
import { MdTimeline } from 'react-icons/md'
import { FaProjectDiagram } from 'react-icons/fa'

export default [
  {
    name: 'Engineering',
    link: '/engineering',
    icon: () => <SiVisualstudiocode />,
  },

  {
    name: 'Design',
    link: '/design',
    icon: () => <SiSketch />,
  },
  {
    name: 'Projects',
    link: '/projects',
    icon: () => <FaProjectDiagram />,
  },
  {
    name: 'Career',
    link: '/career',
    icon: () => <MdTimeline />,
  },
]
