import React, { useContext } from 'react';
import { Box } from 'theme-ui';
import { store } from '../../Context/Store';
export default function Modal() {
  const { globalState, dispatch } = useContext(store);

  const hasChildren = globalState.modal.children;
  const modalMessage = globalState.modal.message;

  return (
    <Box sx={styles.container}>
      <Box
        sx={styles.modalBackground}
        onClick={() => {
          dispatch({ type: 'CLOSE_MODAL', modalOpen: false });
        }}
      />
      <Box sx={styles.modalContent}>
        <Box>{modalMessage}</Box>
        {hasChildren ? hasChildren : ''}
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    position: 'fixed',
    height: '100%',
    width: '100vw',
    top: '0rem',
    left: '0rem',
    zIndex: '9999',
  },

  modalBackground: {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.3)',
    backdropFilter: 'blur(2px)',
  },
  modalContent: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '90%',
    backgroundColor: 'white',
    borderRadius: '20px',
    height: '100%',
    width: '100%',
    maxHeight: ['80vh', '', '70vh'],
    minHeight: '200px',
    padding: ['1rem', '', '2rem'],
    overflow: 'scroll',
  },
};
