export default [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'Engineering',
    link: '/engineering',
  },

  {
    name: 'Design',
    link: '/design',
  },
  {
    name: 'Projects',
    link: '/projects',
  },
  {
    name: 'Career',
    link: '/career',
  },
]
